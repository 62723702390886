import React from 'react';
import logo from './logo.png';
import './App.css';
// Import the functions you need from the SDKs you need
import {initializeApp} from 'firebase/app';
import {getAnalytics} from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBjJh6Kx6XSOOHL1n6uMAMi2Od5sZim26c',
  authDomain: 'imessage-extension-65f45.firebaseapp.com',
  projectId: 'imessage-extension-65f45',
  storageBucket: 'imessage-extension-65f45.appspot.com',
  messagingSenderId: '520993943808',
  appId: '1:520993943808:web:01bd73251d90af126503e5',
  measurementId: 'G-WQ18JT9YBZ',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Kailan on web is coming soon.</p>
        <p>Kailan on iOS is available now.</p>
        <a
          className="App-link"
          href="https://apps.apple.com/us/app/kailan/id6446461616"
          target="_blank"
          rel="noopener noreferrer">
          Download Kailan from the iOS App Store
        </a>
      </header>
    </div>
  );
}

export default App;
